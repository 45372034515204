
span {
    margin-right: 1rem;
}

.container {
    height: 80%;
}

.container img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.imageInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: .25rem;
    margin-bottom: .25rem;
    width: 80%;
}


.rotate-control {
    color: #fff;
    text-shadow: 0 0 5px orange, 0 0 10px orange, 0 0 20px orange;
    animation: glow-pulse 2s infinite;
    cursor: pointer;
  }
  
  @keyframes glow-pulse {
    0%, 100% {
      text-shadow: 0 0 5px var(--link-color), 0 0 10px var(--link-color), 0 0 20px var(--link-color);
    }
    50% {
      text-shadow: 0 0 10px white, 0 0 20px white, 0 0 40px white;
    }
  }

  .rotate-icon {
    color: orange;
    display: inline-block;
    font-size: 1.25rem;
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.slider {
    min-width: 200px;
}

.sliderSetup {
    appearance: none;
    background-color: orange;
    border-radius: 5px;
    width: 100px; 
    height: 8px; 
    outline: none; /* Remove default outline */
  }

  .sliderSetup::-webkit-slider-thumb {
    appearance: none; /* Override default appearance of the thumb */
    width: 20px; /* Set width of the thumb */
    height: 20px; /* Set height of the thumb */
    background: var(--secondary-gradient-color); /* Custom thumb color */
    border-radius: 50%; /* Rounded shape for the thumb */
    border: 1px solid #ccc; /* Border for the thumb */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); /* Shadow effect for the thumb */
    cursor: pointer; /* Cursor style for interaction */
   
}

/* Customize the thumb (slider handle) for Firefox */
.sliderSetup::-moz-range-thumb {
    width: 20px; /* Set width of the thumb */
    height: 20px; /* Set height of the thumb */
    background: var(--secondary-gradient-color); /* Custom thumb color */
    border-radius: 50%; /* Rounded shape for the thumb */
    border: 1px solid #ccc; /* Border for the thumb */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); /* Shadow effect for the thumb */
    cursor: pointer; /* Cursor style for interaction */
}

.title-left {
    font-size: 1rem;
    margin-left: auto;
}

.title-right {
    font-size: 1rem;
    margin-left: auto;
}

/* Targeting iPad Pro in landscape mode */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) {
  
  .container img {
   height: auto;
  }
}

@media (max-width: 1024px) {
    /* .container img {
        height: 30vh;
        object-fit: contain;
        width: 100%;
    } */
    div .slider {
        text-align: left;
        min-width: 125px;
    }
    
    .imageInfo {
        align-content: center;
        margin-left: auto;
        text-align: left;
        width: 80%;
    }
    .title-left {
        margin-right: 2rem;
        min-width: 140px;
    }
    .title-right {
        min-width: 80px;
    }
}

@media (max-width: 768px) {
div .slider {
    text-align: left;
    min-width: 125px;
}

.imageInfo {
    margin-left: 2rem;
    text-align: left;
    width: 90%;
}
.title-left {
    margin-left: 0px;
    margin-right: 2rem;
    min-width: 140px;
}
.title-right {
    min-width: 80px;
}

}

@media (max-width: 480px) {
.imageInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: .5rem;
    margin-left: auto;
    margin-top: .5rem;
}

.slider {
    margin-bottom: 1rem;
}
.title-left {
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    min-width: 0;
}

.title-right {
    font-size: 1rem;
    margin-left: 0;
    min-width: 0;
}

}

@media (max-width: 400px) {
    .imageInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: .5rem;
        margin-left: auto;
        margin-top: .5rem;
    }
    
    .slider {
        margin-bottom: 1rem;
    }
    .title-left {
        font-size: .8rem;
        margin-left: auto;
        margin-right: auto;
        min-width: 0;
    }
    
    .title-right {
        font-size: .8rem;
        margin-left: 0;
        min-width: 0;
    }
}