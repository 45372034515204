h1 {
    font-size: 2rem;
    padding-top: 2rem;
} 

h2 {
    font-size: 1.25rem;
}

.contact-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: var(--link-color);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .contact-button svg {
    font-size: 4rem;  /* Adjust the size */
}

div .galleryContainer {
    height: auto;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 1rem;
    margin-top: 2rem;
    width: 30vw;
}
.customPageContentLayout {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5rem;
    align-items: left;
    justify-content: flex-start;
    width: 70%;
}

.customPageContentLayout p {
    font-size: 2rem;
    padding-bottom: 0rem;
    padding-top: 4rem;
    text-align: left;
}

.customPageContentLayout .customGalleryTextLayout p {
    padding-top: 0rem;
}

.customWorkPageContent {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: top;
    justify-content: flex-start;
    height: 100vh;
    width: 80%;
}

.customGalleryTextLayout {
    display: flex;
    flex-direction: row;
    margin-right: auto;
    padding-left: .5rem;
    align-items: flex-start;
    justify-content: space-between;
    width: 30vw;
}

@media (max-width: 1024px) {

p {
    font-size: 1rem;
    margin-bottom: .5rem;
    padding: 0rem;
    text-align: center;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: 70vw;
}

div .galleryContainer {
    margin-right: auto;
}
.contentToggle {
    display: none;
}
.customPageContentLayout {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-left: 0rem;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
}

.customPageContentLayout p {
    font-size: 1.25rem;
    padding-bottom: 2rem;
    padding-top: 0rem;
    text-align: left;
}

.customWorkPageContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.customGalleryTextLayout {
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    margin-right: auto;
    padding-left: .5rem;
    align-items: flex-start;
    justify-content: space-around;
    width: 90%;
}
}

@media (max-width: 1024px) and (orientation: landscape) {
    p {
        font-size: 1rem;
        margin-bottom: .5rem;
        padding: 0rem;
        text-align: center;
    }
    
    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 6rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
        width: 30vw;
    }
    
    div .galleryContainer {
        margin-right: auto;
    }

    .contact-button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        color: var(--link-color);
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    
      .contact-button svg {
        font-size: 3rem;  /* Adjust the size */
    }

    .contentToggle {
        display: none;
    }
    .customPageContentLayout {
        display: flex;
        flex-direction: column;
        /* margin-left: 2rem; */
        margin-right: auto;
        padding-left: 0rem;
        align-items: center;
        justify-content: flex-start;
        width: 40vw;
    }
    
    .customPageContentLayout p {
        font-size: 1.25rem;
        margin-left: 4rem;
        padding-bottom: 2rem;
        padding-top: 0rem;
        text-align: left;
        width: 40vw;
    }
    
    .customWorkPageContent {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        align-items: top;
        justify-content: start;
        height: 100vh;
        width: 80vw;
    }
    
    .customGalleryTextLayout {
        display: flex;
        flex-direction: row;
        height: 2.5rem;
        margin-right: auto;
        padding-left: .5rem;
        align-items: flex-start;
        justify-content: space-around;
        width: 90%;
    }
}

@media (max-width: 860px) {
    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
        margin-top: 0rem;
        width: 70vw;
    }

    .contact-button svg {
        font-size: 4rem;  /* Adjust the size */
    }

    .customWorkPageContent {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
    }
}

@media (max-width: 380px) {
p {
    font-size: .8rem;
    margin-bottom: .5rem;
    padding: 0rem;
    text-align: left;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0rem;
    margin-top: 0rem;
    width: 70vw;
}

.contact-button svg {
    font-size: 3rem;  /* Adjust the size */
}

div .galleryContainer {
    margin-right: auto;
}
.contentToggle {
    display: none;
}

.customGalleryTextLayout {
    display: flex;
    flex-direction: row;
    font-size: .8rem;
    margin-right: 1rem;
    padding-left: 0rem;
    align-items: left;
    justify-content: space-around
    ;
    width: 80vw;
}

.customPageContentLayout {
    display: flex;
    flex-direction: column;
    /* margin-left: auto; */
    margin-right: auto;
    padding-left: 0rem;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
}

.customPageContentLayout p {
    font-size: 1.1rem;
    padding-bottom: 2rem;
    padding-top: 0rem;
    text-align: left;
}

.customWorkPageContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100vw;
}
}



