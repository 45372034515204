.contactPageContent {
    align-content: left;
    display: flex;
    flex-direction: column;
    height: 73vh;
}

.imageCard {
    align-content: center;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;;
    width: 100vw;
}

.imageCard .artistPhoto img{
    height: 100vh;
    margin-left: 20vw;
    margin-top: 1rem;;
    object-fit: cover ;
    overflow: hidden;
    width: 20vw;
}

.mailToLink {
    color: var(--link-color)
}

.textBox {
    margin-right: 20vh;
    margin-left: 5rem;
    margin-top: 30vh;
    text-align: left;
}

@media (max-width: 1600px) {

    .contactPageContent {
        align-content: left;
        display: flex;
        flex-direction: column;
        height: 73vh;
    }
    
    .imageCard .artistPhoto img{
        height: 100vh;
        margin-left: 2rem;
        object-fit: cover ;
        overflow: hidden;
        width: 40vw;
        
    }

    .textBox {
        margin-right: 20vh;
        margin-left: 1rem;
        margin-top: 15vh;
        text-align: left;
    }
}

@media (max-width: 480px) {

    .contactPageContent {
        align-content: left;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-left: 0;
        margin-right: .5rem;
    }
    .imageCard {
        align-content: center;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: center;
        width: 100vw;
    }

    .imageCard .artistPhoto img{
        border-radius: 5px;
        height: 50vh;
        margin-left: 0;
        margin-top: 1rem;;
        object-fit: cover ;
        overflow: hidden;
        width: 85vw;
        
    }
    a ,.mailToLink {
        font-size: 1rem;
    }
    .textBox {
        margin-bottom: 25vh;
        margin-left: 4rem;

    }
    p .textBox {
        font-size: 1.5rem;

        width: 80vw;
    }


}