* {
    box-sizing: border-box;
    padding: 0;
  }

  h1 {
    font-size: 2rem;
    
  }

  .home p {
    font-size: 1.25rem;
    /* line-height: 150%; */
    margin-right: 1rem;
    width: 20vw

  }

  .home {
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    width: 65vw;
  }

  .welcomeMsg {
    font-family: roboto;
    font-weight: 100;
    font-style: italic;
    margin-left: auto;
    margin-top: 5rem;
  } 

  /* Tablet Media Query */
@media (max-width: 1024px) {
  /* .home {
    max-width: 80vw; /* Adjust the width for tablet screens 
    padding: 20px; Example: add padding for smaller screens 
  } */

  .home {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-content: center;

    justify-content: flex-start;

    min-height: 81vh;
    max-width: 80vw; 
    padding: 0;
    width: 100vw;
  }

  .home p {
    width: 70vw; /* Adjust paragraph width */
    font-size: 1.25rem; /* Decrease font size for readability */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-top: 1rem;
    width: 80vw;
  }

  h1 {
    font-size: 1.5rem; /* Reduce font size for tablets */
  }
}

/* Ipad-Mini Media Query */
@media(min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1){

  .home {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-content: center;

    justify-content: flex-start;

    min-height: 81vh;
    padding: 0;
    width: 100vw;
  }

  .home p {
    width: 50vw; /* Adjust paragraph width */
    font-size: 0.9rem; /* Decrease font size for readability */
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: 5rem;
    width: 80vw;
  }
}

