* {
  box-sizing: border-box;
  padding: 0;
}

h2 {
  font-size: 1.5rem;
}

.about p {
  font-family: roboto;
  font-weight: 100;
  font-size: 1.25rem;
  line-height: 1.5;
}

.about {
  height: fit-content;
  width: 60vw;
  margin: 0 auto; /* Center the content horizontally */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.colOne {
  width: 30vw; /* Adjust based on the desired space */
  text-align: left;
}

.colTwo {
width: 30vw;

}

.colTwo .videoContainer {

  margin-left:5vw;
  margin-top: 2.5rem;
  max-width: 100%; /* Ensure video doesn't exceed its container */
  box-shadow: 10px 10px 5px 0px var(--primary-gradient-color);
}

.imageContainer {
  align-content: center;
  display: flex;
  flex-direction: row;
  height: 60vh;
  justify-content: center;
  margin-left: 5vw;
  margin-right: auto;
  overflow: hidden;;
  width: 30vw;
}

.imageContainer .artistWorkingPhoto img{

  height: 80vh;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;;
  object-fit: cover ;
  overflow: hidden;
  width: 20vw;
}

.imageContainer .artistWorkingDetailPhoto img{

  height: 80vh;
  margin-top: 1rem;;
  object-fit: cover ;
  overflow: hidden;
  width: 20vw;
}


@media (max-width: 1024px) {
  .about {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 90vw; /* Adjusted width for smaller screens */
  }

  .colOne,
  .colTwo {
    margin-top: 2rem;
    width: 100%; /* Full width for columns on smaller screens */
    text-align: left;
  }

  .colTwo .videoContainer {
    margin-left: 0; /* Reset margin for center alignment */
    margin-top: 0;
  }

  .imageContainer {
    display: none;
  }

  p {
    font-size: 1rem;
  }
}



