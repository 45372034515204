.galleryPageContent {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: top;
    justify-content: flex-start;
    height: 100vh;
    width: 80%;
}

@media (max-width: 1024px) {
.galleryPageContent {
    display: flex;
    flex-direction: column;
}
}
