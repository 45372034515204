a {
    font-size: 1.25rem;
}

p {
    padding:0rem;
}
.faqMainContent {
    align-items: left;
    display: flex;
    flex-direction: column; 
    flex-grow: 1; 
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    min-height: 100vh;
    width: 70vw;
}

.faqText {
    font-size: 1.25rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
    text-align: left;
}

.faqTitle {
    font-size: 3rem;
    padding-bottom: 3rem;
    padding-top: 1.5rem;
}

.faqQuestion {
    color: var(--complimentary-link-color);
    font-size: 1.5rem;
    padding-bottom: .5rem;
    padding-top: .5rem;
}

@media (max-width: 1024px){

    a {
        font-size: 1.25rem;
    }

    .faqMainContent {
        display: flex;
        flex-direction: column;
        width: 90vw;
    }

    .faqTitle {
        font-size: 1.5rem;
        padding-bottom: 0rem;
    }

    .faqQuestion {
        font-size: 1.5rem;
        padding-bottom: 0rem;
    }

    .faqText {
        font-size: 1.25rem;
    }
}