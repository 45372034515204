a {
  color: var(--primary-text-color);
  text-decoration: none;
}

a:hover{
  text-decoration:underline;
}

/*site wide color constants*/
:root {
  --primary-gradient-color: #282c34;
  --secondary-gradient-color: #45494E ;
  --primary-text-color: antiquewhite;
  --background-color: #45494E;
  --link-color: #9f9ff3;
  --complimentary-link-color: #eaba6b;
}
 
 .App {
  background-color: var(--background-color);
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  font-family: 'Marcellus SC', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  text-align: center;
} 

.contentContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  max-width: 60vw;
  
}

@media (max-width: 1024px) {
  .App {
    background-color: var(--background-color);
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    font-family: 'Marcellus SC', Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    min-height: 100vh;
    width: 100%;
    justify-content: flex-start;
    text-align: center;
  } 

  .contentContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-width: 100%;
  }
} 

@media (max-width: 1024px) and (orientation: landscape) {
  .App {
    background-color: var(--background-color);
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    font-family: 'Marcellus SC', Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    height: 100vh;
    width: auto;
    justify-content: start;
    text-align: center;
  } 

}

@media (max-width: 768px) {
  .App {
    background-color: var(--background-color);
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    font-family: 'Marcellus SC', Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    text-align: center;
  } 

  .contentContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-width: 100%;
  }
}

/* Phone Media Query */
@media (max-width: 480px) {
  .App {
    background-color: var(--background-color);
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    font-family: 'Marcellus SC', Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    min-height: 100vh;
    width: 100vw;
    justify-content: center;
    text-align: center;
  } 

  .contentContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
    max-width: 100%;
  }
}