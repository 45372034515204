.pageNotFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    height:60vh;
    width: 60vw;
    text-align: center;
  }

  .pageNotFound img {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }