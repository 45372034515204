.slideshow-container {
  border-radius: 10px;
  box-shadow: 5px 5px 25px 5px var(--primary-gradient-color);
  width: 40vw;
  height: 40vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.slideshow-container img {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit:cover;
  transition: opacity 2s ease-in-out; /* Apply opacity transition */
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

/* Phone + Tablet Media Query */
@media (max-width: 1024px) {
  .slideshow-container {
    border-radius: 10px;
    box-shadow: 5px 5px 25px 5px var(--primary-gradient-color);
    width: 80vw;
    height: 80vw;
    position: relative;
    overflow: hidden;
  }


.slideshow-container img {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 2s ease-in-out; /* Apply opacity transition */
}

@media(min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1){

  .slideshow-container img {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    object-fit: cover;
    transition: opacity 2s ease-in-out; /* Apply opacity transition */
  }

  }
}
