
.galleryContainer {
    align-content:flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: left;
    padding-bottom: 1rem;
    padding-left: 0rem;
    height: auto;
    width: 40vw;
}

.thumbnailButton {
    appearance: none;
    border: none;
    border-radius: .5rem;
    height: 30vh;
    margin-bottom: .5rem;
    margin-left: .5rem;
    margin-right: .5rem;
    width: auto;
}

.thumbnailButton img {
    border-radius: .5rem;
    height: 30vh;
    margin-bottom: .5rem;

    width: auto;
}

@media (max-width: 1750px){
    .thumbnailButton {
        appearance: none;
        border: none;
        border-radius: .5rem;
        height: 30vh;
        margin-bottom: .5rem;
        margin-left: .5rem;
        margin-right: .5rem;
        width: auto;
    }
    
    .thumbnailButton img {
        border-radius: .5rem;
        height: 30vh;
        margin-bottom: .5rem;
    
        width: auto;
    }
}

/* Phone Media Query */
@media (max-width: 1024px) {
    .galleryContainer {
        align-content:flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 0rem;
        width: 100%;
    }

    .thumbnailButton {
        appearance: none;
        border: none;
        border-radius: .5rem;
        height: 12rem;
        margin-bottom: 0rem;
        margin-left: .5rem;
        width: 12rem;
    }
    
    .thumbnailButton img {
        border-radius: .5rem;
        height: 12rem;
        margin-bottom: 0rem;
        width: 12rem;
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .galleryContainer {
        align-content:flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 0rem;
        width: 100%;
    }

    .thumbnailButton {
        appearance: none;
        border: none;
        border-radius: .5rem;
        height: 8rem;
        margin-bottom: 0rem;
        margin-left: .5rem;
        width: 8rem;
    }
    
    .thumbnailButton img {
        border-radius: .5rem;
        height: 8rem;
        margin-bottom: 0rem;
        width: 8rem;
    }
}

@media (max-width: 480px) {
    .galleryContainer {
        align-content:flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 1.5rem;
        width: 100%;
    }

    .thumbnailButton {
        appearance: none;
        border: none;
        border-radius: .5rem;
        height: 8rem;
        margin-bottom: 1rem;
        margin-left: .5rem;
        width: 8rem;
    }
    
    .thumbnailButton img {
        border-radius: .5rem;
        height: 8rem;
        margin-bottom: 0rem;
        width: 8rem;
    }
}

@media (max-width: 380px){
    .galleryContainer {
        align-content:flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 0rem;
        width: 100%;
    }

    .thumbnailButton {
        appearance: none;
        border: none;
        border-radius: .5rem;
        height: 7rem;
        margin-bottom: 1rem;
        margin-left: .5rem;
        width: 7rem;
    }
    
    .thumbnailButton img {
        border-radius: .5rem;
        height: 7rem;
        margin-bottom: 0rem;
        width: 7rem;
    }
}

@media (max-width: 360px){
    .galleryContainer {
        align-content:flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 0rem;
        width: 100%;
    }

    .thumbnailButton {
        appearance: none;
        border: none;
        border-radius: .5rem;
        height: 6.5rem;
        margin-bottom: 1rem;
        margin-left: .5rem;
        width: 6.5rem;
    }
    
    .thumbnailButton img {
        border-radius: .5rem;
        height: 6.5rem;
        margin-bottom: 0rem;
        width: 6.5rem;
    }
}

@media only screen 
  and (min-device-width: 1368px) 
  and (max-device-width: 1824px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
  
    .galleryContainer {
        margin-top: 2.5rem;
    }
    
}
