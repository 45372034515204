a {
    color: var(--link-color);
    font-size: 1.5rem;
}

.link {
    color: var(--link-color);
    font-size: 1.5rem;
}

.landingCustomPageContentLayout h1 {
    font-size: 3rem;
    padding-top: 1.5rem;
} 

.landingCustomPageContentLayout h2 {
    font-size: 1.75rem;
    padding-bottom: 2rem;
}

.landingCustomPageContentLayout p {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1;
    padding-bottom: 2rem;
    padding-top: 1.5rem;
    text-align: left;
}

div .mainContent  {

    line-height: auto
}


.landingCustomPageContentLayout {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.landingCustomWorkPageContent {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: top;
    justify-content: flex-start;
    height: 100vh;
    width: 80%;
}

.mainContentLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0rem;
}

@media (max-width: 1024px) {
p {
    padding-top: 1rem;
}
div .galleryContainer {
    margin-right: auto;
}
.landingContentToggle {
    display: none;
}
.landingCustomPageContentLayout {
    display: flex;
    flex-direction: column;
    /* margin-left: auto; */
    margin-right: auto;
    padding-left: 0rem;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
}
.landingCustomWorkPageContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100vw;
}
.landingCustomPageContentLayout p {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1;
    padding-top: .5rem;
    text-align: center;
}
.mainContentLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
}
