h2 {
  font-size: 2rem;
}

p {
    font-family: Marcellus SC, Arial, Helvetica, sans-serif;
    font-weight: 300;
}

.col1 {
  display: flex;
  flex-direction: column;
  margin-left: 10vw;
  text-align: left;
  width: 30vw;
}

.col1 a {
  font-size: 1.5rem;
}

.col2 {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  text-align: left;
}

.event {
  align-content: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  text-align: left;
  width: 35vw;
}

.event a {
  color: var(--link-color);
  text-shadow: 0px 0px 5px black;
}

.event a,
p {
  line-height: 1.5rem;
  font-size: 1.5rem;
}

.eventDescription {
  display: flex;
  flex-direction: row;
  align-content: left;
}

.eventDescription p {
  font-size: 1.25rem;
  font-style: italic;
  padding-left: 1.5rem;
  padding-top: .5rem;
}

.eventsPageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 73vh;
}

@media (max-width: 1400px) {
  .event {
    align-content: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    text-align: left;
    width: 25vw;
  }

  .event a p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  a, p {
    margin-top: .5rem;
  }

  .eventDescription p {
    margin-top: 1rem;
  }
  .col1 {
    margin-bottom: 2rem;
    margin-left: 0;
    text-align: left;
    width: 80vw;
  }

  .col2 {
    text-align: left;
  }

  .event {
    width: 100%;
  }

  .eventDescription {
    align-self: start;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .eventsPageContent {
    align-content: left;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    height: 82vh;
    width: 80vw;
  }
}
