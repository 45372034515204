* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-size: .7rem;
    margin-bottom: 1rem;
  }

  .footer {
    align-items: center;
    background-image: linear-gradient(to top, var(--primary-gradient-color), var(--secondary-gradient-color));
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    height: 10vh;
    width: 100vw;
  }