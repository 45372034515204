* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.headerMain h1 {
  font-size: 3rem;
  margin-top: 1rem;
}

.headerMain {
  align-items: center;
  background-image: linear-gradient(to bottom, var(--primary-gradient-color), var(--secondary-gradient-color));
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  min-height: 15vh;
  margin-bottom: 2rem;
  width: 100vw;
  position: relative; /* Position relative for absolute positioning of menu */
}

.navToggle {
  display: none; /* Hide by default */
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.active {
  color: var(--link-color);
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: transform 0.4s ease, opacity 0.4s ease; /* Transition for transform and opacity */
}

/* Rotate first and third bar for hamburger animation */
.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.open .bar:nth-child(2) {
  opacity: 0; /* Hide the middle bar when menu is open */
}

.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.navMenu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-top: 1rem;
}

 .headerMain .navMenu li a {
  font-size: 1.5rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  .headerMain h1 {
    font-size: 2rem;
  }

  .headerMain {
    min-height: 10vh;
  }

  .navMenu {
    display: none; /* Hide the menu items by default */
    flex-direction: column;
    position: absolute;
    top: 70px; /* Adjust top position as needed */
    left: 0;
    background-color: var(--primary-gradient-color); /* Match header background */
    width: 100vw;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add box shadow */
    z-index: 1000; /* Ensure menu appears above content */
  }

  /* Show the menu when 'open' class is present */
  .navMenu.open {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .navMenu li {
    font-size: 1.2rem;
    margin: 1rem 0;
    text-align: center;
  }

  .navToggle {
    display: inline-block; /* Hide by default */
    cursor: pointer;
    position: absolute;
    top: 5rem;
    right: 5rem;
  }
}

@media (max-width: 768px)
   and (max-height: 1024px){
    
  .headerMain .navMenu li a {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .navMenu {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .navToggle {
    display: inline-block; /* Hide by default */
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}

/* Phone Media Query */
@media (max-width: 480px) {


  .headerMain h1 {
    font-size: 1.5rem;
  }

  .headerMain {
    min-height: 5vh;
  }
  
  .navToggle {
    display: block; /* Show the toggle button */
  }

  .navMenu {
    display: none; /* Hide the menu items by default */
    flex-direction: column;
    position: absolute;
    top: 70px; /* Adjust top position as needed */
    left: 0;
    background-color: var(--primary-gradient-color); /* Match header background */
    width: 100vw;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add box shadow */
    z-index: 1000; /* Ensure menu appears above content */
  }

  /* Show the menu when 'open' class is present */
  .navMenu.open {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .navMenu li {
    font-size: 1.2rem;
    margin: 1rem 0;
    text-align: center;
  }
}
